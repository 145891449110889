import React from 'react';


import packageJson from '../package.json';
const logoImg = require('../assets/images/cscf-logo-trans.png');

const logo = () => (
  <div className="Logo">
      <div>
    <img alt="logo" src={logoImg} />
    {/* <label style={{margin:'5px' , color:'#CCC'}}>V {packageJson.version}</label> */} 
  </div>
  </div>
);
export default logo;
