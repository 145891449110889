import React from "react";
import moment from "moment";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Logo from "./Logo";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(3, 2)
    },
    rootHeader: {
      paddingLeft: theme.spacing(3)
    }
  })
);

export default function PaperSheet() {
  const classes = useStyles();

  return (
    <main className="Content">
      <div className={"Toolbar"}>
        <AppBar position="static" className={"AppBar-bg"}>
          <Logo />
          <ul className="NavigationItems">
            <span />
            {/*
                 <LangPicker />
                */}
          </ul>
        </AppBar>
      </div>
      <Typography variant="h4" className={classes.rootHeader} component="h3">
        Système d'information info fauna
      </Typography>

      <Link href="/infofauna/base/" target="_blank">
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            Base
          </Typography>
          <Typography component="p">
            Paramétrage du système d'information info fauna
          </Typography>
        </Paper>
      </Link>
      <Link href="/infofauna/thesaurus/" target="_blank">
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            Thésaurus
          </Typography>
          <Typography component="p">Liste des domaines du thésaurus</Typography>
        </Paper>
      </Link>

      <Link href="/infofauna/systematic/" target="_blank" >
        <Paper className={classes.root}  elevation={0} >
          <Typography variant="h5" component="h3">
            Systématique
          </Typography>
          <Typography component="p">
            Gestion des données de la systématique
          </Typography>
        </Paper>
      </Link>
      <Link href="/midat/" target="_blank" >
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            MDR ( MIDAT-Rivières )
          </Typography>
          <Typography component="p">
            MIDAT-Rivières est un système d’information pour l’évaluation des
            indices biotiques de la qualité des cours d’eau avec l’aide de
            macrozoobenthos.
          </Typography>
        </Paper>
      </Link>
      <Link href="/infofauna/mds/" target="_blank" >
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            MDS ( MIDAT-Sources )
          </Typography>
          <Typography component="p">
            MIDAT-Sources est un système d’information qui intègre toutes les
            données relatives aux études éco-morphologiques et faunistiques des
            sources.
          </Typography>
        </Paper>
      </Link>
      <div className={"Footer-bg"}>
        <Typography variant="subtitle2" component="h6">
          {" "}
          © 2014 - {moment().format("YYYY")} CSCF{" "}
        </Typography>
      </div>
    </main>
  );
}
